// @ts-strict-ignore
import keyBy from 'lodash/keyBy';
import React from 'react';

import CheckboxFilter from 'src/responses/components/filters/checkbox_filter';
import DateFilter from 'src/responses/components/filters/date_filter';
import DropdownFilter from 'src/responses/components/filters/dropdown_filter';
import TextFilter from 'src/responses/components/filters/text_filter';
import AttachmentFilter from 'src/responses/components/filters/attachment_filter';

type ChoiceData = {
  displayText: string;
  count?: number;
};

type FilterProps = {
  filterKey: string;
  filterLabel: string;
  currentFilterValues: string;
  filterChoices?: ChoiceData[];
  filterPrompt?: string | null;
};

type Props = {
  campaign: Campaign;
  filters: object;
  headers: Responses.TableHeader[];
};

function ResponsesFilters(props: Props): React.JSX.Element {
  const {
    campaign,
    headers,
    filters,
  } = props;

  return (
    <div className='responses-filters'>
      <ul className='list-inline'>
        {
          formDataFilterMenus(
            campaign.formDataFilters,
            headers,
            filters,
          )
        }
        {
          fieldFilterMenus(
            campaign.filterFields,
            filters,
          )
        }
      </ul>
    </div>
  );
}

// private
function formDataFilterMenus(
  formDataFilters,
  headers,
  filters,
): React.JSX.Element[] {
  const headersByAttribute = keyBy(headers, 'attribute');

  return (
    Object.keys(formDataFilters).map((filterKey) => {
      if (!headersByAttribute[filterKey]) { return null; }

      const propsForFilter: FilterProps = {
        currentFilterValues: filters[filterKey],
        filterKey,
        filterLabel: headersByAttribute[filterKey].title,
      };

      const formDataFilter = formDataFilters[filterKey];

      if (formDataFilter.dataType === 'list') {
        propsForFilter.filterChoices = formDataFilter.filterChoices;
      }

      const isTextFilter =
        formDataFilter.dataType === 'text' || formDataFilter.dataType === 'number';

      if (isTextFilter) {
        propsForFilter.filterPrompt = headersByAttribute[filterKey].filterPrompt;
      }

      return filterComponent(propsForFilter, formDataFilter.dataType);
    })
  );
}

function fieldFilterMenus(
  fields,
  filters,
): React.JSX.Element[] {
  return (
    fields.map((field) => {
      const propsForFilter: FilterProps = {
        currentFilterValues: filters[field.number],
        filterKey: field.number,
        filterLabel: field.label,
      };

      if (field.dataType === 'list') {
        propsForFilter.filterChoices = field.dropdownChoices.map((choice) => {
          return { displayText: choice };
        });
      }

      return filterComponent(propsForFilter, field.dataType);
    })
  );
}

function filterComponent(propsForFilter, dataType): React.JSX.Element {
  const { filterKey } = propsForFilter;

  switch (dataType) {
    case 'list':
      return <li key={filterKey}><DropdownFilter {...propsForFilter} /></li>;
    case 'boolean':
      return <li key={filterKey}><CheckboxFilter {...propsForFilter} /></li>;
    case 'date':
      return <li key={filterKey}><DateFilter {...propsForFilter} /></li>;
    case 'text':
    case 'number':
      return <li key={filterKey}><TextFilter {...propsForFilter} /></li>;
    case 'attachment':
      return <li key={filterKey}><AttachmentFilter {...propsForFilter} /></li>;
    default:
      return null;
  }
}

export default ResponsesFilters;
export type { Props };
