// @ts-strict-ignore
import 'jquery.signaturepad';

import A11yDialog from 'a11y-dialog';
import $ from 'jquery';
import keyBy from 'lodash/keyBy';
import Toposort from 'toposort-class';

import { fontRatio } from 'src/constants';
import FormulaDecorator from 'src/decorators/formula';
import buildFieldsCollection from 'src/form_filler/build_fields_collection';
import Field from 'src/form_filler/fields/field';
import FormulaField from 'src/form_filler/fields/formula';
import FormatValidator from 'src/form_filler/format_validator';
import FormulaEvaluator from 'src/form_filler/formula_evaluator';
import prettify from 'src/form_filler/prettify';
import ReminderModal from 'src/form_filler/reminder_modal';
import Signature from 'src/form_filler/signature';
import { assert, narrow } from 'src/helpers/assertion';
import Location from 'src/helpers/location';
import notify from 'src/helpers/notify';
import sanitizeInput from 'src/helpers/sanitize_input';
import { setTooltip } from 'src/helpers/tooltip';
import valToString from 'src/helpers/val_to_string';

type SavedSignature = {
  name: string;
  hash: string;
  clear?: boolean;
};

const SCROLL_OPTIONS: ScrollIntoViewOptions = {
  behavior: 'smooth',
  block: 'center',
  inline: 'center',
};

class FormFiller {
  $element: JQuery;
  element: HTMLElement;
  fields: Field[];
  savedSignature: SavedSignature;
  formulaEvaluator: FormulaEvaluator;
  loadingDialog: A11yDialog;

  constructor(element) {
    this.element = element;
    this.$element = $(element);

    this.onChangeRefreshFormulas = this.onChangeRefreshFormulas.bind(this);
    this.finishSubmission = this.finishSubmission.bind(this);
    this.saveInfo = this.saveInfo.bind(this);
    this.validateAndSubmitForm = this.validateAndSubmitForm.bind(this);

    this.fields = buildFieldsCollection();

    const formulaFields: FormulaField[] =
      this.fields.filter((field): field is FormulaField => {
        return field.type === 'FormulaField';
      });

    this.formulaEvaluator = new FormulaEvaluator({ formulaFields });

    $('.signature').each((_index, signature) => {
      new Signature(signature, {
        getSavedSignature: this.getSavedSignature.bind(this),
        updateSavedSignature: this.updateSavedSignature.bind(this),
      });
    });

    this.loadingDialog = this.initLoadingModal();

    const showLoadingSpinner = this.loadingDialog &&
      $('.form-filler-loading-modal').data('displayImmediately');

    if (showLoadingSpinner) { this.loadingDialog.show(); }

    this.showFaqModal(showLoadingSpinner);
    initReminderModal();
    this.initIncompleteFiller();
    initFontStyle();
    this.turnOffAlertsOnClick();
  }

  get $requiredFields(): JQuery {
    const requiredFieldSelector = [
      '.user_data_field.required:not([data-disabled="true"])',
      '.requested-field.required:not([disabled])',
      '.requested_dropdown.required:not([disabled])',
    ].join(', ');

    return this.$element.find(requiredFieldSelector);
  }

  turnOffAlertsOnClick(): void {
    const selectors = [
      '.set-vals-message > span > a, .unset-vals-message > a',
      '.reject-button',
      '.deny-button',
    ];

    selectors.forEach((selector) => {
      $(selector).on('click', turnOffPageLeaveWarning);
    });
  }

  initIncompleteFiller(): void {
    if (this.$element.data('completed')) { return; }

    this.evaluateFormulas();
    this.fields.forEach(
      (field) => { field.onChange = this.onChangeRefreshFormulas; },
    );
    this.loadSavedSignature();
    this.activateSaveAndSubmitButtons();

    sanitizeInput(this.$element.find('.user_data_field'));

    this.$element.find('#filler-submit').on('click', this.validateAndSubmitForm);
    this.$element.find('.save-progress-btn').on('click', this.saveInfo);

    if (!this.$element.data('no-warn')) { Location.addBeforeUnload('form-filler'); }

    this.$element.on('keydown', checkEnter);

    if (this.loadingDialog) { this.loadingDialog.hide(); }
  }

  loadSavedSignature(): void {
    const savedSignature = $('.form-filler').data('savedSignature');

    this.updateSavedSignature(savedSignature || {});
  }

  getSavedSignature(): SavedSignature {
    return this.savedSignature;
  }

  updateSavedSignature(payload): void {
    this.savedSignature = payload;
  }

  activateSaveAndSubmitButtons(): void {
    this.$element.find('.save-progress-btn').prop('disabled', false);
    this.$element.find('#filler-submit').prop('disabled', false);
  }

  deactivateSaveAndSubmitButtons(): void {
    this.$element.find('.save-progress-btn').prop('disabled', true);
    this.$element.find('#filler-submit').prop('disabled', true);
  }

  fieldFormatValid(): boolean {
    return Array.from(this.$element.find('[data-format]')).every(validateFormat);
  }

  formulasValid(): boolean {
    const $formulaFields = $('.formula-field > .display-text');
    const invalidField = Array.from($formulaFields).find((field) => {
      return $(field).text() === 'Invalid';
    });

    if (invalidField) {
      const $invalidField = $(invalidField);

      invalidField.scrollIntoView(SCROLL_OPTIONS);
      setTooltip($invalidField, 'Calculated values are invalid.');
      return false;
    }

    return true;
  }

  initLoadingModal(): A11yDialog {
    const modalElement: HTMLElement =
      document.querySelector('.form-filler-loading-modal');

    if (!modalElement) { return null; }

    return new A11yDialog(modalElement);
  }

  evaluateFormulas(): void {
    if (!this.formulaEvaluator) { return; }

    const fieldsByNumber = keyBy(this.fields, 'number');
    const sortedFormulaFieldNumbers =
      this.sortedFormulaFieldNumbers(this.formulaEvaluator.formulaFields);

    sortedFormulaFieldNumbers.forEach((fieldNumber) => {
      const formulaField = narrow(fieldsByNumber[fieldNumber], FormulaField);
      const result =
        this.formulaEvaluator.recalculateField(fieldsByNumber, formulaField);

      if (result) {
        formulaField.handleChange(result.value);
      }
    });
  }

  onChangeRefreshFormulas(): void {
    if (!this.formulaEvaluator) { return; }

    const fieldsByNumber = keyBy(this.fields, 'number');
    const results = this.formulaEvaluator.recalculateFields(fieldsByNumber);

    results.forEach(({ formulaField, value }) => {
      formulaField.handleChange(value);
    });
  }

  sortedFormulaFieldNumbers(formulaFields: FormulaField[]): number[] {
    const tsort = new Toposort();
    const formulaFieldNumbers =
      formulaFields.map((field) => { return field.number; });

    formulaFields.forEach((field) => {
      const formula = new FormulaDecorator(field.fieldContent);
      const dependentFieldNumbers = formula.getFieldNumbers()
        .filter((fieldNum) => { return formulaFieldNumbers.includes(fieldNum); })
        .map((fieldNum) => { return fieldNum.toString(); });

      tsort.add(field.number.toString(), dependentFieldNumbers);
    });

    return tsort.sort().reverse();
  }

  showFaqModal(showingLoadingSpinner: boolean): void {
    const modalElement: HTMLElement =
      document.querySelector('div[data-a11y-dialog="form-filler-faq-modal"]');

    if (!modalElement) { return; }

    const faqDialog = new A11yDialog(modalElement);

    if (showingLoadingSpinner) {
      this.loadingDialog.on('hide', () => {
        faqDialog.show();
      });
    } else {
      faqDialog.show();
    }
  }

  validateAndSubmitForm(event: Event): boolean {
    if (!this.allRequiredFieldsFilled()) { return false; }
    if (!this.allRequiredSigsFilled()) { return false; }
    if (!this.allRequiredAttachmentFieldsAttached()) { return false; }
    if (!this.requiredUnnamedAttachmentsAttached()) { return false; }
    if (!this.fieldFormatValid()) { return false; }
    if (!this.formulasValid()) { return false; }

    preventDoubleSubmit(event);

    this.$element.find('#form_request_action').val('Submit form');

    this.finishSubmission();

    return true;
  }

  saveInfo(): void {
    this.$element.find('#form_request_action').val('Save progress');
    this.finishSubmission();
  }

  finishSubmission(): void {
    turnOffPageLeaveWarning();

    const data = this.getSubmitData();

    Object.entries(data).forEach(([key, value]) => {
      $(`#${key}`).val(value);
    });

    this.setCheckboxData();

    try {
      this.appendPrettifiedParams();
    } catch (error) {
      notify(
        new Error('Problem trying to prettify form filler params'),
        { campaignId: this.$element.data('campaign-id'), originalError: error },
      );
    }

    if (this.loadingDialog) { this.loadingDialog.show(); }
    this.deactivateSaveAndSubmitButtons();
    this.$element.trigger('submit');
  }

  appendPrettifiedParams(): void {
    const prettifiedParams = prettify(this.$element);
    const $prettyEl = $("<input type='hidden' name='prettified'>");

    $prettyEl.val(JSON.stringify(prettifiedParams));
    this.$element.append($prettyEl);
  }

  getSubmitData(): { [key: string]: string } {
    const data = {};

    Object.entries(this.$element.data('form-data')).forEach(([key, value]) => {
      data[`form_request_${key}`] = value;
    });

    return data;
  }

  setCheckboxData(): void {
    const $checkboxes = Array.from($('.requested_checkbox:not([disabled])'));
    const checkboxData = $checkboxes.map((checkbox) => {
      return $(checkbox).is(':checked') ? 'x' : '';
    });

    const checkboxIds = $checkboxes.map((checkbox) => {
      return $(checkbox).val();
    });

    $('#checks').val(checkboxData.join(','));
    $('#check_ids').val(checkboxIds.join(','));
  }

  allRequiredFieldsFilled(): boolean {
    const invalidFields = Array.from(this.$requiredFields).filter((field) => {
      return !getValue($(field));
    });

    if (invalidFields.length === 0) { return true; }

    invalidFields.forEach((invalidField) => {
      $(invalidField).addClass('required-danger');
    });

    const $firstInvalidField = $(assert(invalidFields[0]));

    invalidFields[0].scrollIntoView(SCROLL_OPTIONS);
    focusUnlessMobile($firstInvalidField);
    setTooltip($firstInvalidField, 'Please complete this field.');
    return false;
  }

  allRequiredSigsFilled(): boolean {
    const requiredSignatures =
      Array.from(this.$element.find('.signature:not([hidden])'))
        .filter((signature) => {
          return Boolean($(signature).find('.add-sig.required').length);
        });
    let allSignaturesFilled = true;

    requiredSignatures.forEach((signature) => {
      const $signature = $(signature);
      const $addSig = $signature.find('.add-sig');
      const signatureName =
        valToString($signature.find('.signature-pad-name').val()).trim();
      const isSigPresent = signatureName !== '';

      $addSig.toggleClass('required-danger', !isSigPresent);

      if (isSigPresent || !allSignaturesFilled) { return; }

      allSignaturesFilled = false;
      const sigModalId = $signature.find('.signature-modal').attr('id');
      const $sigLink = $(`a[href="#${sigModalId}"]`);

      $addSig[0].scrollIntoView(SCROLL_OPTIONS);
      focusUnlessMobile($sigLink);

      setTooltip($addSig, 'Please add this signature.');
    });

    return allSignaturesFilled;
  }

  allRequiredAttachmentFieldsAttached(): boolean {
    const requiredAttachments = Array.from<HTMLElement>(
      this.element.querySelectorAll(
        '[data-field-type="AttachmentField"]:not([hidden]) ' +
        '.attachment-field.required',
      ),
    );
    return requiredAttachments.reduce(
      (allHaveAttachments, field) => {
        const hasAttachment = !field.classList.contains('empty');
        field.classList.toggle('required-danger', !hasAttachment);

        if (!hasAttachment) {
          field.scrollIntoView(SCROLL_OPTIONS);
          focusUnlessMobile($(field));
        }

        return allHaveAttachments && hasAttachment;
      },
      true,
    );
  }

  requiredUnnamedAttachmentsAttached(): boolean {
    const attachmentsRequired = document.querySelector(
      '.attachment-uploader.required',
    );
    const attachedCount = document.querySelectorAll(
      '.js-submitted-attachments  > tbody > tr, .uploader-filename',
    ).length;

    if (Boolean(attachmentsRequired) && attachedCount === 0) {
      attachmentsRequired.classList.add('empty');
      attachmentsRequired.scrollIntoView();
      return false;
    }
    return true;
  }
}

// private

function initFontStyle(): void {
  const rawFontSize = parseInt(valToString($('#font_size_doc_view').val()), 10);
  const fontSize = Math.floor(rawFontSize * fontRatio);
  const lineHeight = Math.floor((rawFontSize + 1) * fontRatio);

  $('.view_tag, .requested-field, .user_data_field, .signature, .attachment-field')
    .not('.tag_checkbox, .filled_checkbox')
    .css('font-size', `${fontSize}px`)
    .css('line-height', `${lineHeight}px`);
}

function initReminderModal(): void {
  const modalElement =
    document.querySelector<HTMLElement>('#form-filler-reminder-modal');

  if (!modalElement) { return; }

  new ReminderModal(modalElement);
}

function turnOffPageLeaveWarning(): void {
  Location.removeBeforeUnload('form-filler');
}

function focusUnlessMobile($element: JQuery): void {
  if (window.orientation) { return; }

  $element.trigger('focus');
}

function getValue($field: JQuery): string | null {
  if ($field.is('.requested_dropdown, .requested-field')) {
    return valToString($field.val()).trim();
  }

  return $field.text().trim(); // prefill field using contenteditable
}

function checkEnter(event): boolean {
  const txtArea = (/textarea/i).test((event.target || event.srcElement).tagName);

  return txtArea || event.key !== 'Enter';
}

function validateFormat(field: HTMLElement): boolean {
  const $field = $(field);
  const format = $field.data('format');
  const value = getValue($field);
  const errorMessage = FormatValidator.validate(format, value);

  if (!errorMessage) { return true; }

  field.scrollIntoView(SCROLL_OPTIONS);
  focusUnlessMobile($field);

  setTooltip($field, errorMessage);

  return false;
}

function preventDoubleSubmit(event: Event): void {
  event.preventDefault();
  event.stopPropagation();
}

export default FormFiller;
