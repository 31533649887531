// @ts-strict-ignore
import NumberFormulaValidator from './number_formula_validator';
import TimeFormulaValidator from './time_formula_validator';

import { FormulaErrors } from './index';

const GenericFormulaValidator = {
  validate(
    formulaField: Field,
    fieldsByNumber: FieldsByNumber,
    stepsById: IndexedSteps,
  ): FormulaErrors | null {
    let errors: string[];

    if (formulaField.mode === 'number') {
      errors = NumberFormulaValidator
        .validate(formulaField, fieldsByNumber, stepsById);
    } else if (formulaField.mode === 'time') {
      errors = TimeFormulaValidator
        .validate(formulaField, fieldsByNumber, stepsById);
    } else {
      return null;
    }

    return { errors, number: formulaField.number };
  },

  validateAll(
    fieldsByNumber: FieldsByNumber,
    stepsById: IndexedSteps,
  ): FormulaErrors[] {
    const formulaFields = findAllFormulaFields(fieldsByNumber);

    return formulaFields.map((formulaField) => {
      return this.validate(formulaField, fieldsByNumber, stepsById);
    }).filter((formulaErrors) => { return formulaErrors; });
  },
};

// private

function findAllFormulaFields(fieldsByNumber: FieldsByNumber): Field[] {
  return Object.values(fieldsByNumber)
    .filter((field) => { return field.type === 'Formula'; });
}

export default GenericFormulaValidator;
