// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';

import { findEl, findEls } from 'src/helpers/finders';

type Section = { id: string; start: number; end: number; button: HTMLElement };

class CampaignSettingsController extends Controller {
  sections: Section[];

  connect(): void {
    this.sections = this.buildSections();
    this.highlightSections();
    this.highlightSections = this.highlightSections.bind(this);
    window.addEventListener('scroll', this.highlightSections);
  }

  scrollTo(event: ActionEvent<{ section: string }>): void {
    const selectedSection = findEl(this.element, 'div', `#${event.params.section}`);
    const { sections } = this;
    window.scrollTo({ top: selectedSection.offsetTop });
    // If a section cannot be scrolled all the way to the top, we still want to
    // show it as active in the navigation. Wait until the next event cycle
    // (after 'scroll' listeners have been called) to mark the navigation link
    // as active.
    setTimeout(() => {
      sections.forEach((section) => {
        this.toggleActive(section.button, section.id === event.params.section);
      });
    });
  }

  private buildSections(): Section[] {
    const sectionSelector = '.settings-section:not(#delete-campaign)';
    const sections = findEls(this.element, 'div', sectionSelector);
    const offset = sections[0].offsetTop;

    return sections.map((element, index, array) => {
      const start = element.offsetTop - offset;
      const nextElement = array[index + 1];
      const end = nextElement ? nextElement.offsetTop - offset : Infinity;
      const buttonSelector =
        `.sidenav__link[data-campaign-settings-section-param="${element.id}"]`;
      const button = findEl(this.element, 'button', buttonSelector);

      return { button, end, id: element.id, start };
    });
  }

  private highlightSections(): void {
    const { scrollTop } = document.documentElement;
    this.sections.forEach((section) => {
      const force = scrollTop >= section.start && scrollTop < section.end;
      this.toggleActive(section.button, force);
    });
  }

  private toggleActive(element: HTMLElement, force: boolean): void {
    element.classList.toggle('sidenav__link--active', force);
  }
}

export default CampaignSettingsController;
